

// 过滤 选中 数据
export function filterListKey(list = [],key = 0) {
    return list && list.map((item:any,index)=>{
        if ( item.code == key )item.active = true;
        else item.active = false;
        return item;
    }) || []
}
