import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import GuessBoxStoreType from "@/views/Other/GuessBox/Store/indexType";

@Module({
    name:"GuessBox",
    store,
    dynamic: true,
})
export default class GuessBox extends VuexModule implements GuessBoxStoreType{

    // 选中 盒子列表
    GuessBoxList: any[] = [];
    get getGuessBoxList(){
        return this.GuessBoxList
    }
    @Mutation
    SetGuessBoxList(data: any[]): void {
        this.GuessBoxList = data
    }

    // 游戏 数据
    GuessBoxData: any = {};
    get getGuessBoxData(){
        return this.GuessBoxData
    }
    @Mutation
    SetGuessBoxData(data: any): void {
        this.GuessBoxData = data
    }

    // 游戏 描述
    GuessBoxMessage: any = {};
    get getGuessBoxMessage(){
        return this.GuessBoxMessage
    }
    @Mutation
    SetGuessBoxMessage(data: any): void {
        this.GuessBoxMessage = data
    }

    // 查看中奖
    GuessBoxLottery: any = {};
    get getGuessBoxLottery(){
        return this.GuessBoxLottery
    }
    @Mutation
    SetGuessBoxLottery(data: any): void {
        this.GuessBoxLottery = data
    }
}

export const GuessBoxStore = getModule( GuessBox )
