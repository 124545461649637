

export const GuessList = [
    { code:1,active:false },
    { code:2,active:false },
    { code:3,active:false },
    { code:4,active:false },
    { code:5,active:false },
    { code:6,active:false },
    { code:7,active:false },
    { code:8,active:false },
    { code:9,active:false },
    { code:0,active:false },
]


export const StatusStr = {
    "0":"活动未开始",
    "1":"活动已开始",
    "2":"活动投票完成",
    "3":"正在发送优惠券",
    "4":"活动本轮结束",
}
